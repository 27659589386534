<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        La CCM A a été développé avec do dichlorométhane comme éluant. « 1 » correspond à un composé
        #1 alors que « 2 » représente un composé #2. Quelle CCM a été développé dans un mélange 1 :1
        d’acétate d’éthyle et d’hexanes?
      </p>

      <p class="pl-12 mb-6">
        <v-img style="max-width: 360px" :src="imageName" />
      </p>

      <v-radio-group v-model="inputs.plate" :disabled="!allowEditing" class="mb-n6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'uOttawa1321Prelab1Q8Fr',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        plate: null,
      },
      options: [
        {text: 'Plate A', value: 'A'},
        {text: 'Plate B', value: 'B'},
        {text: 'Plate C', value: 'C'},
        {text: 'Plate D', value: 'D'},
        {text: 'Plate E', value: 'E'},
        {text: 'Plate F', value: 'F'},
      ],
    };
  },
  computed: {
    imageNumber() {
      return this.taskState.getValueBySymbol('imageNumber').content;
    },
    imageName() {
      return '/img/assignments/Q8-TLC-V' + this.imageNumber.value + '.png';
    },
  },
};
</script>
